<template>
  <div class="date-select-wrap">
    <v-select
      attach="#menu"
      height="40"
      :append-icon="'mdi-chevron-down'"
      v-model="selectValue"
      :items="items"
      @change="getActivityByDate"
    ></v-select>
    <div id="menu"></div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    direction: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      items: [
        {
          text: "Past 30 days",
          value: 30,
        },
        {
          text: "Past 60 days",
          value: 60,
        },
        {
          text: "Past 90 days",
          value: 90,
        },
        {
          text: "90+ days",
          value: "all activities",
        },
      ],
      selectValue: {
        text: "Past 30 days",
        value: 30,
      },
    };
  },
  computed: {
    ...mapGetters({
      user: "wallet/user",
      activeFilter: "wallet/activeFilter",
      activityCount: "wallet/activityCount",
    }),
  },
  methods: {
    getActivityByDate() {
      let dateFrom = new Date();

      if (isNaN(this.selectValue)) {
        dateFrom = "";
      } else {
        dateFrom.setDate(dateFrom.getDate() - this.selectValue);
      }

      this.$store.dispatch("wallet/getUserActivity", {
        filter: this.activeFilter,
        direction: this.direction === "income" ? "income" : "outgoing",
        skip: 0,
        limit: 10,
        dateFrom: dateFrom.toLocaleString("en-US"),
        dateTo: new Date().toLocaleString("en-US"),
      });

      this.$store.commit("wallet/setCurrentDateFilter", this.selectValue);
    },
  },
};
</script>

<style lang="scss" scoped>
.date-select-wrap {
  position: relative;
}

.v-select {
  min-width: 160px;
  width: 160px;
  height: 40px;
  padding: 0;
  margin: 0;
  ::v-deep .v-input__control {
    height: 100%;
    .v-input__slot {
      height: 100%;
      border-radius: $radius-md;
      box-shadow: $box-shadow-medium;
      padding: 0px 10px 0px 16px;
      margin: 0;
      .v-select__slot {
        font-size: 14px;
        font-weight: bold;
      }
      &::before,
      &::after {
        display: none;
      }
    }
  }

  &::v-deep.v-select--is-menu-active {
    .v-input__slot {
      border-bottom-left-radius: $no-radius;
      border-bottom-right-radius: $no-radius;
    }
    & ~ #menu {
      .v-menu__content {
        box-shadow: $select-box-shadow;
        border-top-left-radius: $no-radius;
        border-top-right-radius: $no-radius;
        &.theme--dark {
          box-shadow: $box-shadow-dark;
        }
      }
    }
  }

  &.theme--dark {
    ::v-deep .v-input__control {
      .v-input__slot {
        box-shadow: $box-shadow-dark;
      }
    }

    &.v-select--is-menu-active {
      ::v-deep .v-input__slot {
        border-bottom-left-radius: $no-radius;
        border-bottom-right-radius: $no-radius;
      }
    }
  }
}

#menu {
  position: absolute;
  bottom: 0;
  ::v-deep .v-menu__content {
    box-shadow: $box-shadow-medium;
    border-top-left-radius: $no-radius;
    border-top-right-radius: $no-radius;
    border-bottom-left-radius: $radius-md;
    border-bottom-right-radius: $radius-md;
    .v-select-list {
      padding: 0;
    }
    .v-list-item {
      min-height: 0;
      &.v-list-item--active {
        font-weight: bold;

        .v-list-item__title {
          background-color: transparent;
          color: var(--v-primary-base);
        }
      }
    }
    .v-list-item__title {
      font-size: 12px;
    }
  }
}

@media #{map-get($display-breakpoints, 'md-and-down')} {
  .date-select-wrap .date-select {
    height: 30px;
  }
}
</style>
